'use client';

import React, { FC, useEffect } from 'react';

import cn from 'classnames';
import { Collapse } from '../Collapse/Collapse';
import { ChevronDown } from '../Icons/Paths/ChevronDown';

import { AccordionProps } from './Accordion.types';

import ButtonIcon from '../Button/Components/ButtonIcon';
import styles from './styles/Accordion.module.css';

const CustomToggleHeaderText = ({
  isOpen,
  customToggleTextHeader,
}: {
  isOpen: boolean;
  customToggleTextHeader: { openText: string; closeText: string };
}) => {
  return isOpen ? (
    <span className={styles.toggleText}>{customToggleTextHeader?.closeText}</span>
  ) : (
    <span className={styles.toggleText}>{customToggleTextHeader?.openText}</span>
  );
};

export const Accordion: FC<AccordionProps> = React.forwardRef<HTMLElement, AccordionProps>(
  (
    {
      children,
      title,
      open = false,
      Component = 'div',
      disabled = false,
      dataTestId,
      className,
      customRightElement,
      onExpandedChange,
      onTransitionEnd,
      hideArrow,
      titleIcon,
      renderWhenClosed = true,
      ...restProps
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = React.useState(open);
    const classNames = cn(
      styles.accordion,
      {
        [styles.accordionOpen]: isOpen,
      },
      className,
    );
    const headerClassNames = cn('typography-body-1', styles.header, {
      [styles.headerOpen]: isOpen,
      [styles.headerDisabled]: disabled,
    });

    const handleHeaderButtonChange = (
      event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      if (disabled) return event.preventDefault();

      setIsOpen(!isOpen);
      if (onExpandedChange) {
        onExpandedChange(!isOpen);
      }
    };

    useEffect(() => {
      if (disabled && isOpen) setIsOpen(false);
    }, [disabled]);

    const tabIndex = disabled ? -1 : 0;

    useEffect(() => {
      open ? setIsOpen(true) : setIsOpen(false);
    }, [open]);

    return (
      <Component
        className={classNames}
        data-accordion-component="Accordion"
        data-test-id={dataTestId}
        data-expanded={isOpen}
        ref={ref}
        {...restProps}
      >
        <div
          aria-expanded={isOpen}
          className={headerClassNames}
          data-accordion-component="AccordionItemButton"
          onClick={handleHeaderButtonChange}
          onKeyPress={handleHeaderButtonChange}
          role="button"
          tabIndex={tabIndex}
        >
          {titleIcon ? (
            <ButtonIcon className={styles.titleIcon} side="left">
              {titleIcon}
              {title}
            </ButtonIcon>
          ) : (
            title
          )}
          {customRightElement}
          {hideArrow ? null : restProps?.customtoggletextheader ? (
            <CustomToggleHeaderText
              isOpen={isOpen}
              customToggleTextHeader={restProps.customtoggletextheader}
            />
          ) : (
            <div className={styles.headerArrow}>
              <ChevronDown aria-hidden="true" color="inherit" />
            </div>
          )}
        </div>
        {(isOpen || renderWhenClosed) && (
          <Collapse
            className="typography-body-3"
            data-accordion-component="AccordionItemPanel"
            onTransitionEnd={onTransitionEnd}
            open={isOpen}
            role="region"
          >
            {children}
          </Collapse>
        )}
      </Component>
    );
  },
);

Accordion.displayName = 'Accordion';
